<template>
  <div>
    <div class="btns">
      <el-button @click="addUser">添加应用管理员</el-button>
    </div>
    <div class="table">
      <el-table :data="list" border>
        <el-table-column
            type="index"
            label="序号"
            width="60"
            align="center"
        >
        </el-table-column>
        <el-table-column
            prop="username"
            label="管理员账户名"
            align="center"
        >
        </el-table-column>
        <el-table-column
            prop="appId"
            label="应用ID"
            align="center"
        >
        </el-table-column>
        <el-table-column
            prop="createTime"
            label="创建时间"
            align="center"
            :formatter="(row)=>dateFun.getCurrentDate(true, ['-', ':'], row.createTime)"
        >
        </el-table-column>
        <el-table-column
            prop="status"
            label="状态"
            align="center"
        >
          <template slot-scope="scope">
            <el-button v-if="scope.row.status === 1" size="mini" type="success" @click="changeStatus(scope.row)">正常
            </el-button>
            <el-button v-else size="mini" type="warning" @click="changeStatus(scope.row)">已停用</el-button>
          </template>
        </el-table-column>
        <el-table-column
            label="操作"
            align="center"
        >
          <template slot-scope="{row}">
            <el-button size="mini" type="primary" @click="editRow(row)">编辑</el-button>
            <el-button size="mini" type="danger" @click="deleteRow(row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--  添加/编辑管理员  -->
    <el-dialog
        title="添加"
        :visible.sync="dialogVisible"
        width="500px"
    >
      <el-form ref="form" :model="form" label-width="120px" :rules="rules">
        <el-form-item label="选择绑定应用*" prop="appId">
          <el-select v-model="form.appId" placeholder="请选择" >
            <el-option
                v-for="item in appList"
                :key="item._id"
                :label="item.name"
                :value="item._id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="账号名*" prop="username">
          <el-input v-model="form.username" placeholder="请输入账号名"></el-input>
        </el-form-item>
        <el-form-item label="账号初始密码" prop="password">
          <el-input type="password" v-model="form.password" placeholder="不填写默认初始密码：123456"></el-input>
        </el-form-item>
        <el-form-item label="绑定手机号">
          <el-input type="password" v-model="form.phone" placeholder="绑定手机号"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="addSubmit">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import {createUser, getAppList, getUserlist, updateUser} from "@/api/base";
import dateFun from "@/utils/dateFun";

export default {
  name: "appManagementUser",
  computed: {
    dateFun() {
      return dateFun
    }
  },
  data() {
    return {
      list: [],
      appList:[],
      dialogVisible:false,
      form:{

      },
      rules:{
        appId:{ required: true, message: '绑定应用必选', trigger: 'blur' },
        username:{ required: true, message: '请输入账号名', trigger: 'blur' }
      }
    }
  },
  mounted() {
    this.getList()
    this.getAppList()
  },
  methods: {
    getAppList() {
      getAppList().then(res => {
        this.appList = res.data
      })
    },
    getList() {
      getUserlist().then(res => {
        this.list = res.data
      })
    },
    addUser(){
      this.dialogVisible = true
    },
    addSubmit(){
      this.$refs['form'].validate((valid) => {
        if (valid) {
          createUser(this.form).then(res=>{
              if(res.code === 0){
                this.$message({
                  message:res.msg,
                  type:"success"
                })
                this.dialogVisible = false
                this.getList()
              }else{
                this.$message({
                  message:res.msg,
                  type:"error"
                })
              }
            })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    changeStatus(row){
      updateUser({status:row.status===0?1:0,_id: row._id}).then(()=>{
        this.getList()
      })
    },
    deleteRow(){

    },
    editRow(row){
      this.form = row
      //this.dialogVisible = true
    }
  }
}
</script>

<style scoped lang="less">
.btns {
  margin-bottom: 20px;
}
</style>
